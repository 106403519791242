import React from "react"

const Home: React.FC = () => {
  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">Home</h1>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <p>
          Welcome to Personal Inventory
        </p>
      </div>
    </main>
  </>
}

export default Home
