import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
  AmplifyAuthContainer
} from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { useEffect, useState } from 'react'
import AuthenticatedApp from './AuthenticatedApp'
import { useAppDispatch } from './hooks'
import { setUser } from './store/reducers/userReducer'

const App = () => {
  const [authState, setAuthState] = useState<AuthState>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    return onAuthUIStateChange(async (nextAuthState, authData) => {
      setAuthState(nextAuthState)
      if (!authData) return
      dispatch(setUser({
        attributes: (authData as any).attributes
      }))
    })
  }, [dispatch])

  return (
    <AmplifyAuthContainer>
      <AmplifyAuthenticator
        usernameAlias="email"
      >
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: "email",
              inputProps: { required: true, autocomplete: "username" },
            },
            {
              type: "password",
              inputProps: { required: true, autocomplete: "new-password" },
            },
          ]}
        />
        <AmplifySignIn
          slot="sign-in"
          usernameAlias="email"
        />
        { authState === AuthState.SignedIn && (
          <AuthenticatedApp />
        )}
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  )
}

export default App
