import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserAttributes {
  sub: string
  email_verified: boolean
  email: string
}

export type UserState = null | {
  attributes?: UserAttributes
  metadata?: any
}

export const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState,
  reducers: {
    setUser(_state, action: PayloadAction<{attributes: UserAttributes}>) {
      return {
        attributes: action.payload.attributes,
      }
    },
    setUserMetadata(state, action: PayloadAction<{metadata: any}>) {
      if (!state) throw new Error('set user first')
      state.metadata = action.payload.metadata
    }
  }
})

export const { setUser, setUserMetadata } = userSlice.actions

export default userSlice.reducer
