import React, { useCallback } from "react"
import { useRouteMatch } from "react-router-dom"
import { formatDistance, parseISO } from 'date-fns'
import { PlusIcon, MinusIcon } from '@heroicons/react/solid'
import Breadcrumbs from "../components/Breadcrumbs"
import PageTitleWithActionButtons from "../components/PageTitleWithActionButtons"
import {
  useGetItemContainerByIdQuery,
  useGetItemByItemContainerIdAndItemIdQuery,
  useGetItemHistoriesByItemContainerIdAndItemIdQuery,
  useCreateItemHistoryMutation,
  ItemHistoryHistoryKind,
  ItemHistoryRecord,
} from "../store/api"
import { classNames } from "../utils"
import LoadingSpinner from "../components/LoadingSpinner"

type ItemHistoryFeedProps = {
  itemHistories: ItemHistoryRecord[]
}

const ItemHistoryFeed: React.FC<ItemHistoryFeedProps> = (props) => {
  const { itemHistories } = props

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {itemHistories.map((h, eventIdx) => (
          <li key={h.id}>
            <div className="relative pb-8">
              {eventIdx !== itemHistories.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      h.kind === "ACQUIRE" && 'bg-green-500',
                      h.kind === "CONSUME" && 'bg-gray-400',
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                    )}
                  >
                    { h.kind === "ACQUIRE" &&
                      <PlusIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    }
                    { h.kind === "CONSUME" &&
                      <MinusIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    }
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      { h.kind === "ACQUIRE" && `Acquired ${h.quantity}` }
                      { h.kind === "CONSUME" && `Used ${h.quantity}` }
                    </p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    <time dateTime={h.createAt}>
                      {formatDistance(parseISO(h.createAt), new Date(), { addSuffix: true })}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const ItemDetail: React.FC = () => {
  const match = useRouteMatch<{spaceId: string, itemId: string}>()
  const { spaceId, itemId } = match.params
  const { data: detail, isLoading: detailLoading } = useGetItemContainerByIdQuery(spaceId)
  const { data: item, isLoading: itemLoading } = useGetItemByItemContainerIdAndItemIdQuery([spaceId, itemId])
  const { data: itemHistories, isLoading: itemHistoriesLoading } = useGetItemHistoriesByItemContainerIdAndItemIdQuery([spaceId, itemId])
  const [
    createItemHistory,
  ] = useCreateItemHistoryMutation()

  const addInventory = useCallback<React.MouseEventHandler<HTMLButtonElement>>((ev) => {
    ev.preventDefault()
    createItemHistory({
      itemContainerId: spaceId,
      itemId,
      kind: ItemHistoryHistoryKind.ACQUIRE,
      quantity: 1,
    })
  }, [createItemHistory, spaceId, itemId])

  const consumeInventory = useCallback<React.MouseEventHandler<HTMLButtonElement>>((ev) => {
    ev.preventDefault()
    createItemHistory({
      itemContainerId: spaceId,
      itemId,
      kind: ItemHistoryHistoryKind.CONSUME,
      quantity: 1,
    })
  }, [createItemHistory, spaceId, itemId])

  if (!detail || detailLoading || !item || itemLoading || !itemHistories || itemHistoriesLoading) {
    return <LoadingSpinner />
  }

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: false },
          { name: detail.name, linkTo: `/spaces/${spaceId}`, current: true },
          { name: item.name, linkTo: `/spaces/${spaceId}/items/${itemId}`, current: true },
        ]} />

        <PageTitleWithActionButtons
          buttons={[
            { title: "Edit", linkTo: `/spaces/${spaceId}/items/${itemId}/edit` },
          ]}
        >
          <div className="flex-shrink-0">
            <div className="relative">
              <img
                className="h-16 w-16 rounded-full"
                src={item.fileUuid ? `https://ucarecdn.com/${item.fileUuid}/-/scale_crop/128x128/smart/-/quality/smart/${item.fileUuid}` : "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"}
                alt={item.name}
              />
              <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
            </div>
          </div>
          {/*
            Use vertical padding to simulate center alignment when both lines of text are one line,
            but preserve the same layout if the text wraps without making the image jump around.
          */}
          <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-gray-900">{item.name}</h1>
            <p className="text-sm font-medium text-gray-500">
              Current count: {item.currentCount}
            </p>
          </div>
        </PageTitleWithActionButtons>
      </div>
    </header>
    <main className="mt-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative mb-8 z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            onClick={addInventory}
            className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-indigo-600 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Add Inventory
          </button>
          <button
            type="button"
            onClick={consumeInventory}
            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            Consume Inventory
          </button>
        </div>
        <ItemHistoryFeed itemHistories={itemHistories} />
      </div>
    </main>
  </>
}

export default ItemDetail
