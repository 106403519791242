import React from "react"
import { PlusIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom"
import { useGetItemContainersQuery } from "../store/api"
import Breadcrumbs from "../components/Breadcrumbs"
import LoadingSpinner from "../components/LoadingSpinner"

const ItemContainer: React.FC<{itemContainer: any}> = (props) => {
  const {itemContainer} = props

  return <div
    className="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
  >
    <h3 className="text-lg font-medium">
      <Link to={`/spaces/${itemContainer.id}`} className="focus:outline-none">
        {/* Extend touch target to entire panel */}
        <span className="absolute inset-0" aria-hidden="true" />
        {itemContainer.name}
      </Link>
    </h3>
  </div>
}

const Spaces: React.FC = () => {
  const { data: itemContainers, isLoading } = useGetItemContainersQuery(undefined)

  let innerSection: JSX.Element
  if (isLoading || typeof itemContainers === "undefined") {
    innerSection = <LoadingSpinner />
  } else if (itemContainers.length === 0) {
    innerSection = (
      <div className="text-center">
        <svg
          className="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
          />
        </svg>
        <h3 className="mt-2 text-sm font-medium text-gray-900">No spaces</h3>
        <p className="mt-1 text-sm text-gray-500">Get started by creating a space for your items!</p>
        <div className="mt-6">
          <Link
            to="/spaces/new"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Space
          </Link>
        </div>
      </div>
    )
  } else {
    innerSection = (
      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {itemContainers.map(ic => <ItemContainer itemContainer={ic} key={ic.id} />) }
      </div>
    )
  }

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: true },
        ]} />
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Spaces</h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <Link
              to="/spaces/new"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New Space
            </Link>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        {innerSection}
      </div>
    </main>
  </>
}

export default Spaces
