import { useRef, useLayoutEffect } from "react"



type BarcodeDetectionResult = {
  format: string
  rawValue: string
}

export type BarcodeScannerProps = {
  onBarcodeDetection: (results: BarcodeDetectionResult[], image?: string) => void
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = (props) => {
  // const [detectedBarcodes, setDetectedBarcodes] = useState<string[]>([])
  const supported = 'BarcodeDetector' in window
  const videoRef = useRef<HTMLVideoElement>(null)
  const barcodeDetectionCallback = props.onBarcodeDetection

  useLayoutEffect(() => {
    const imageCanvas = document.createElement('canvas')

    const detectedValues: Set<string> = new Set()
    let currentAnimationFrame: number | undefined = undefined
    if (videoRef.current === null) return
    const video = videoRef.current
    let media: MediaStream
    let cleanup = false
    ;(async () => {
      media = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
        },
      })
      video.srcObject = media
      //@ts-ignore
      const detector = new window.BarcodeDetector()
      const capture = async () => {
        if (cleanup) { return }
        const barcodes: BarcodeDetectionResult[] = await detector.detect(video)
        if (barcodes.length > 0) {
          const results = barcodes
            .filter((item) => (item.format === "ean_13" || item.format === "ean_8") && !detectedValues.has(item.rawValue))
            .map((item) => ({ format: item.format, rawValue: item.rawValue }))
          results.forEach((item) => {
            detectedValues.add(item.rawValue)
            // setDetectedBarcodes(codes => ([...codes, item.rawValue]))
          })

          if (barcodeDetectionCallback.length === 2) {
            imageCanvas.width = video.videoWidth
            imageCanvas.height = video.videoHeight
            imageCanvas.getContext('2d')?.drawImage(video, 0, 0, imageCanvas.width, imageCanvas.height)
            barcodeDetectionCallback(results, imageCanvas.toDataURL())
          } else {
            barcodeDetectionCallback(results)
          }
        }

        currentAnimationFrame = window.requestAnimationFrame(capture)
      }
      video.addEventListener("play", () => {
        capture()
      })
    })()

    return () => {
      cleanup = true
      if (currentAnimationFrame) {
        window.cancelAnimationFrame(currentAnimationFrame)
      }
      if (media) {
        media.getTracks().forEach(track => track.stop())
      }
    }
  }, [barcodeDetectionCallback])

  if (!supported) {
    return null
  }

  return <>
    <div>
      <video
        ref={videoRef}
        autoPlay={true}
      />
    </div>
  </>
}

export default BarcodeScanner
