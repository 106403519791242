import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Uploadcare, { Widget as UploadcareWidget } from '@uploadcare/react-widget'
import UploadClient from '@uploadcare/upload-client'
import BarcodeScanner, { BarcodeScannerProps } from '../components/BarcodeScanner'
import Breadcrumbs from '../components/Breadcrumbs'
import {
  ProductSearchResult,
  useCreateItemMutation,
  useGetItemContainerByIdQuery,
  useGetProductSearchQuery
} from '../store/api'

interface BarcodeScannerWrapperProps {
  onProductSelected: (result: ProductSearchResult & { janCode: string }) => void
}

const KEY_CODE_MAP: { [key: string]: number } = {
  'Digit1': 1,
  'Digit2': 2,
  'Digit3': 3,
  'Digit4': 4,
  'Digit5': 5,
  'Digit6': 6,
  'Digit7': 7,
  'Digit8': 8,
  'Digit9': 9,
  'Digit0': 0,
}

function isValidBarcode(value: string) {
  // We only allow correct length barcodes
  if (!value.match(/^(\d{8}|\d{12,14})$/)) {
    return false;
  }

  const paddedValue = value.padStart(14, '0');

  let result = 0;
  for (let i = 0; i < paddedValue.length - 1; i += 1) {
    result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
  }

  return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
}

const BarcodeScannerWrapper: React.FC<BarcodeScannerWrapperProps> = ({onProductSelected}) => {
  const [ currentJanCode, setCurrentJanCode ] = useState<string>("")
  const { data: productSearchResults } = useGetProductSearchQuery(currentJanCode, {
    skip: currentJanCode === "",
  })

  const handleDetection = useCallback<BarcodeScannerProps["onBarcodeDetection"]>((results, image) => {
    // results.forEach(item => console.log(`format=${item.format} val=${item.rawValue}`))
    const firstProduct = results[0]
    if (firstProduct) {
      setCurrentJanCode(firstProduct.rawValue)
    }
  }, [])

  useLayoutEffect(() => {
    const currentBuffer: number[] = []
    const handler = (ev: KeyboardEvent) => {
      if (ev.code === 'Enter') {
        const codeStr = currentBuffer.join('')
        currentBuffer.length = 0
        if (isValidBarcode(codeStr)) {
          setCurrentJanCode(codeStr)
        }
      } else if (ev.code in KEY_CODE_MAP) {
        currentBuffer.push(KEY_CODE_MAP[ev.code])
      }
    }
    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [])

  return <div className="sm:col-span-6">
    <BarcodeScanner
      onBarcodeDetection={handleDetection}
    />
    { typeof productSearchResults !== 'undefined' &&
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {productSearchResults.map((searchResult) => (
          <li key={searchResult.id} className="relative">
            <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
              <img src={searchResult.imageUrl} alt={searchResult.name} className="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => onProductSelected({...searchResult, janCode: currentJanCode})}>
                <span className="sr-only">{searchResult.name}</span>
              </button>
            </div>
            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{searchResult.name}</p>
            {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">{file.size}</p> */}
          </li>
        ))}
      </ul>
    }
  </div>
}

interface CreateNewItemFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement
  initialCount: HTMLInputElement
}

const CreateNewItem: React.FC = () => {
  const match = useRouteMatch<{spaceId: string}>()
  const { spaceId } = match.params
  const history = useHistory()
  const nameRef = useRef<HTMLInputElement>(null)
  const janCodeRef = useRef<string | null>(null)
  const fileUUIDRef = useRef<string | null>(null)
  const [ productUCUUID, setProductUCUUID ] = useState<string | undefined>(undefined)
  const [
    createItem,
  ] = useCreateItemMutation()
  const { data: spaceDetail } = useGetItemContainerByIdQuery(spaceId)

  const ucClient = useMemo(() => {
    return new UploadClient({ publicKey: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY })

  }, [])

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(async (ev) => {
    ev.preventDefault()
    const values = ev.currentTarget.elements as CreateNewItemFormElements
    const resp = await createItem({
      itemContainerId: spaceId,
      name: values.name.value,
      initialCount: values.initialCount.value ? parseInt(values.initialCount.value, 10) : undefined,
      ianCode: janCodeRef.current !== null ? janCodeRef.current : undefined,
      fileUuid: fileUUIDRef.current !== null ? fileUUIDRef.current : undefined,
    })
    if ('error' in resp && resp.error) {
      // handle error
    } else if ('data' in resp) {
      history.push(`/spaces/${spaceId}`)
    }
  }, [ history, spaceId, createItem ])

  const handleCancel = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (ev) => {
    ev.preventDefault()
    history.goBack()
  }, [ history ])

  const handleProductSelected = useCallback<BarcodeScannerWrapperProps["onProductSelected"]>((result) => {
    nameRef.current!.value = result.name
    janCodeRef.current = result.janCode
    ucClient
      .uploadFile(result.imageUrl, {
        publicKey: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY!
      })
      .then((file) => {
        setProductUCUUID(file.uuid)
      })
  }, [ucClient])

  const handleFileUploaded = useCallback<(file: Uploadcare.FileInfo) => void>((file) => {
    fileUUIDRef.current = file.uuid
    setProductUCUUID(undefined)
  }, [])

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: false },
          { name: (spaceDetail ? spaceDetail.name : '...'), linkTo: `/spaces/${spaceId}`, current: false },
          { name: "New Item", linkTo: `/spaces/${spaceId}/items/new`, current: true },
        ]} />
        <h1 className="text-3xl font-bold leading-tight text-gray-900">Create a new Item</h1>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <BarcodeScannerWrapper onProductSelected={handleProductSelected} />
                <div className="sm:clo-span-6">
                  <UploadcareWidget
                    publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY!}
                    tabs="file camera url"
                    imagesOnly={true}
                    clearable={true}
                    value={productUCUUID}
                    onChange={handleFileUploaded}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      ref={nameRef}
                      type="text"
                      name="name"
                      placeholder="Potatoes"
                      required
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="initialCount" className="block text-sm font-medium text-gray-700">
                    Initial Count
                  </label>
                  <div className="mt-1">
                    <input
                      type="number"
                      name="initialCount"
                      min={0} step={1}
                      defaultValue={0}
                      required
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </>
}

export default CreateNewItem
