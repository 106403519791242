import { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Breadcrumbs from '../components/Breadcrumbs'
import LoadingSpinner from '../components/LoadingSpinner'
import { useUpdateItemContainerMutation, useGetItemContainerByIdQuery } from '../store/api'

interface EditSpaceFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement;
}

const EditSpace: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch<{spaceId: string}>()
  const { spaceId } = match.params
  const { data: detail, isLoading: detailLoading } = useGetItemContainerByIdQuery(spaceId)

  const [
    updateSpace,
  ] = useUpdateItemContainerMutation()

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(async (ev) => {
    ev.preventDefault()
    const values = ev.currentTarget.elements as EditSpaceFormElements
    const resp = await updateSpace({
      id: spaceId,
      name: values.name.value,
    })
    if ('error' in resp && resp.error) {
      // handle error
    } else if ('data' in resp) {
      history.push(`/spaces/${resp.data.id}`)
    }
  }, [updateSpace, spaceId, history])

  const handleCancel = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (ev) => {
    ev.preventDefault()
    history.goBack()
  }, [ history ])

  if (!detail || detailLoading) return <LoadingSpinner />

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: false },
          { name: detail.name, linkTo: `/spaces/${detail.id}`, current: false },
          { name: "Edit", linkTo: `/spaces/${detail.id}/edit`, current: true },
        ]} />
        <h1 className="text-3xl font-bold leading-tight text-gray-900">Editing "{detail.name}"</h1>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      placeholder="My Home"
                      defaultValue={detail.name}
                      required
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </>
}

export default EditSpace
