import { Link } from "react-router-dom"

type ActionButton = {
  title: React.ReactNode
  primary?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  linkTo?: string
}

type Props = {
  buttons?: ActionButton[]
}

const PRIMARY_BUTTON_CLASSES = "inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
const SECONDARY_BUTTON_CLASSES = "inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"

const PageTitleWithActionButtons: React.FC<Props> = (props) => {
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">
        {props.children}
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        {(props.buttons || []).map((def, index) => {
          if (def.onClick) return (
            <button
              key={index}
              type="button"
              className={def.primary ? PRIMARY_BUTTON_CLASSES: SECONDARY_BUTTON_CLASSES}
              onClick={def.onClick}
            >{def.title}</button>
          )
          if (def.linkTo) return (
            <Link
              key={index}
              to={def.linkTo}
              className={def.primary ? PRIMARY_BUTTON_CLASSES: SECONDARY_BUTTON_CLASSES}
            >{def.title}</Link>
          )
          return null
        })}
      </div>
    </div>
  )
}

export default PageTitleWithActionButtons
