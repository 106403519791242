import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userReducer'
import { api } from './api'
import { setupListeners } from '@reduxjs/toolkit/query'

const store = configureStore({
  reducer: {
    user: userReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(api.middleware)
  }
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
