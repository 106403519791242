import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import store from './store'
import { Provider } from 'react-redux'
import App from './App'
import Amplify from 'aws-amplify'
import reportWebVitals from './reportWebVitals'

Amplify.configure({
  "aws_project_region": "ap-northeast-1",
  // "aws_cognito_identity_pool_id": "YOUR_IDENTITY_POOL_ID",
  "aws_cognito_region": "ap-northeast-1",
  "aws_user_pools_id": process.env.REACT_APP_COGNITO_USERPOOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  "oauth": {}
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
