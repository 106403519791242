import { PlusIcon } from "@heroicons/react/solid"
import { Link, useRouteMatch } from "react-router-dom"
import Breadcrumbs from "../components/Breadcrumbs"
import LoadingSpinner from "../components/LoadingSpinner"
import PageTitleWithActionButtons from "../components/PageTitleWithActionButtons"
import {
  useGetItemContainerByIdQuery,
  useGetItemsByItemContainerIdQuery,
} from "../store/api"

const SpacesDetail: React.FC = () => {
  const match = useRouteMatch<{spaceId: string}>()
  const { spaceId } = match.params
  const { data: detail, isLoading: detailLoading } = useGetItemContainerByIdQuery(spaceId)
  const { data: items, isLoading: itemsLoading } = useGetItemsByItemContainerIdQuery(spaceId)

  if (!detail || detailLoading) {
    return <LoadingSpinner />
  }

  if (!items || itemsLoading) {
    return <LoadingSpinner />
  }
  const itemsInner = <>
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      {items.map((item, idx) => (
        <li key={item.id} className="relative">
          <Link
            to={`/spaces/${spaceId}/items/${item.id}`}
            className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
          >
            <img src={item.fileUuid ? `https://ucarecdn.com/${item.fileUuid}/-/resize/512x/-/quality/smart/${item.fileUuid}` : `https://source.unsplash.com/random/512x512?sig=${idx}`} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
          </Link>
          <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{item.name}</p>
          <p className="block text-sm font-medium text-gray-500 pointer-events-none">
            Current count: {item.currentCount}
          </p>
        </li>
      ))}
    </ul>
  </>

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: false },
          { name: detail.name, linkTo: `/spaces/${spaceId}`, current: true },
        ]} />

        <PageTitleWithActionButtons
          buttons={[
            {
              title: "Edit",
              linkTo: `/spaces/${match.params.spaceId}/edit`,
            },
            {
              title: <><PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" /> New Item</>,
              linkTo: `/spaces/${match.params.spaceId}/items/new`,
              primary: true,
            },
          ]}
        >
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{detail.name}</h2>
        </PageTitleWithActionButtons>
      </div>
    </header>
    <main className="mt-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {itemsInner}
      </div>
    </main>
  </>
}

export default SpacesDetail
