import { Auth } from "aws-amplify"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

const Signout: React.FC = () => {
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      await Auth.signOut()
      history.push("/")
    })()
  }, [history])
  
  return null
}

export default Signout
