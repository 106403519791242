import React, { useCallback, useEffect, useRef, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import Uploadcare, { Widget as UploadcareWidget } from '@uploadcare/react-widget'
import Breadcrumbs from "../components/Breadcrumbs"
import LoadingSpinner from "../components/LoadingSpinner"
import PageTitleWithActionButtons from "../components/PageTitleWithActionButtons"
import {
  useGetItemContainerByIdQuery,
  useGetItemByItemContainerIdAndItemIdQuery,
  useUpdateItemMutation,
  ItemRecordUpdate,
  useDeleteItemMutation,
} from "../store/api"

interface EditItemFormElements extends HTMLFormControlsCollection {
  name: HTMLInputElement
  initialCount: HTMLInputElement
}

const ItemEdit: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch<{spaceId: string, itemId: string}>()
  const { spaceId, itemId } = match.params
  const { data: spaceDetail, isLoading: spaceDetailLoading } = useGetItemContainerByIdQuery(spaceId)
  const { data: item, isLoading: itemLoading } = useGetItemByItemContainerIdAndItemIdQuery([spaceId, itemId])

  const fileUUIDRef = useRef<string | null>(null)
  const [ productUCUUID, setProductUCUUID ] = useState<string | undefined>(undefined)

  useEffect(() => {
    setProductUCUUID(item?.fileUuid)
  }, [item?.fileUuid]);

  const [ updateItem ] = useUpdateItemMutation()
  const [ deleteItem ] = useDeleteItemMutation()

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(async (ev) => {
    ev.preventDefault()
    const values = ev.currentTarget.elements as EditItemFormElements
    const updates: ItemRecordUpdate = {
      id: itemId,
      itemContainerId: spaceId,
      name: values.name.value,
    }
    if (fileUUIDRef.current !== null) {
      updates.fileUuid = fileUUIDRef.current
    }
    const resp = await updateItem(updates)
    if ('error' in resp && resp.error) {
      // handle error
    } else if ('data' in resp) {
      history.push(`/spaces/${spaceId}/items/${itemId}`)
    }
  }, [itemId, spaceId, updateItem, history])

  const handleCancel = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (ev) => {
    ev.preventDefault()
    history.goBack()
  }, [ history ])

  const handleDelete = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (ev) => {
    ev.preventDefault()
    if (!window.confirm("Are you sure you want to delete this item?")) {
      return
    }
    const respPromise = deleteItem({ itemContainerId: spaceId, id: itemId })
    history.push(`/spaces/${spaceId}`)
    await respPromise
  }, [deleteItem, history, itemId, spaceId])

  const handleFileUploaded = useCallback<(file: Uploadcare.FileInfo) => void>((file) => {
    fileUUIDRef.current = file.uuid
    setProductUCUUID(undefined)
  }, [])

  if (!spaceDetail || spaceDetailLoading || !item || itemLoading) {
    return <LoadingSpinner />
  }

  return <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={[
          { name: "Spaces", linkTo: `/spaces`, current: false },
          { name: spaceDetail.name, linkTo: `/spaces/${spaceId}`, current: false },
          { name: item.name, linkTo: `/spaces/${spaceId}/items/${itemId}`, current: false },
          { name: "Edit", linkTo: `/spaces/${spaceId}/items/${itemId}/edit`, current: true },
        ]} />

        <PageTitleWithActionButtons>
          <div className="flex-shrink-0">
            <div className="relative">
              <img
                className="h-16 w-16 rounded-full"
                src={item.fileUuid ? `https://ucarecdn.com/${item.fileUuid}/-/scale_crop/128x128/smart/-/quality/smart/${item.fileUuid}` : "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"}
                alt={item.name}
              />
              <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
            </div>
          </div>
          {/*
            Use vertical padding to simulate center alignment when both lines of text are one line,
            but preserve the same layout if the text wraps without making the image jump around.
          */}
          <div className="pt-1.5">
            <h1 className="text-2xl font-bold text-gray-900">{item.name}</h1>
            <p className="text-sm font-medium text-gray-500">
              Current count: {item.currentCount}
            </p>
          </div>
        </PageTitleWithActionButtons>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:clo-span-6">
                  <UploadcareWidget
                    publicKey={process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY!}
                    tabs="file camera url"
                    imagesOnly={true}
                    clearable={true}
                    value={productUCUUID}
                    onChange={handleFileUploaded}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      placeholder="Potatoes"
                      defaultValue={item.name}
                      required
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleCancel}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleDelete}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </main>
  </>
}

export default ItemEdit
