import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Auth } from 'aws-amplify'

export interface ProductSearchResult {
  name: string
  id: string
  imageUrl: string
  url: string
}

export interface BaseRecord {
  createAt: string
  updateAt: string
}

export interface SparseItemContainerRecord extends BaseRecord {
  id: string
  name: string
}

export interface ItemContainerRecord extends SparseItemContainerRecord {
}

export type ItemContainerRecordCreate = Pick<ItemContainerRecord, "name">
export type ItemContainerRecordUpdate = Pick<ItemContainerRecord, "id"> & ItemContainerRecordCreate

export interface ItemRecord extends BaseRecord {
  id: string
  itemContainerId: string

  currentCount: number
  name: string
  ianCode?: string
  fileUuid?: string
}

export type ItemRecordCreate =
  Pick<ItemRecord, "itemContainerId" | "name" | "ianCode" | "fileUuid"> &
  { initialCount?: number }

export type ItemRecordUpdate =
  Pick<ItemRecord, "itemContainerId" | "id"> &
  Partial<Pick<ItemRecord, "name" | "ianCode" | "fileUuid">>

export type ItemRecordDelete =
  Pick<ItemRecord, "itemContainerId" | "id">

export enum ItemHistoryHistoryKind {
  ACQUIRE = 'ACQUIRE',
  CONSUME = 'CONSUME',
}

export interface ItemHistoryRecord extends BaseRecord {
  id: string
  itemId: string

  kind: ItemHistoryHistoryKind
  quantity: number
}

export type ItemHistoryRecordCreate =
  Pick<ItemHistoryRecord, "itemId" | "kind" | "quantity"> &
  { itemContainerId: string }

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL || "https://personal-inventory-api.kmproj.com/dev/",
    prepareHeaders: async (headers) => {
      const session = await Auth.currentSession()
      const token = session.getAccessToken().getJwtToken()
      headers.set('authorization', token)
      return headers
    },
  }),
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [
    'ItemContainer',
    'Item',
    'ItemHistory',
    'ProductSearch',
  ],
  endpoints: (builder) => ({
    getUserMetadata: builder.query({
      query: () => `user/me`,
    }),
    getProductSearch: builder.query<ProductSearchResult[], string>({
      query: (janCode) => `productSearch?jan_code=${janCode}`,
      transformResponse: (resp: any) => resp.products,
      providesTags: (_result, _error, janCode) => ([
        { type: 'ProductSearch', id: janCode },
      ]),
    }),
    getItemContainers: builder.query<SparseItemContainerRecord[], undefined>({
      query: () => `itemContainers`,
      transformResponse: (resp: any) => resp.itemContainers,
      providesTags: (result) => (
        result ?
          [
            ...result.map(({ id }) => ({ type: 'ItemContainer', id } as const)),
            { type: 'ItemContainer', id: 'LIST' },
          ] : [
            { type: 'ItemContainer', id: 'LIST' },
          ]
      ),
    }),
    createItemContainer: builder.mutation<ItemContainerRecord, ItemContainerRecordCreate>({
      query: (args) => ({
        url: 'itemContainers',
        method: 'POST',
        body: args,
      }),
      transformResponse: (resp: any) => resp.itemContainer,
      invalidatesTags: [
        { type: 'ItemContainer', id: 'LIST' },
      ],
    }),
    updateItemContainer: builder.mutation<ItemContainerRecord, ItemContainerRecordUpdate>({
      query: (args) => ({
        url: `itemContainers/${args.id}`,
        method: 'PATCH',
        body: args,
      }),
      transformResponse: (resp: any) => resp.itemContainer,
      invalidatesTags: (_result, _error, {id}) => ([
        { type: 'ItemContainer', id },
      ]),
    }),
    getItemContainerById: builder.query<ItemContainerRecord, string>({
      query: (ic_id) => `itemContainers/${ic_id}`,
      transformResponse: (resp: any) => resp.itemContainer,
      providesTags: (_result, _error, id) => [{ type: 'ItemContainer', id }]
    }),
    getItemsByItemContainerId: builder.query<ItemRecord[], string>({
      query: (id) => `itemContainers/${id}/items`,
      transformResponse: (resp: any) => resp.items,
      providesTags: (result, _error, ic_id) => (
        result ?
          [
            ...result.map(({ id }) => ({ type: 'Item', id } as const)),
            { type: 'Item', id: `${ic_id}:LIST` },
          ] : [
            { type: 'Item', id: `${ic_id}:LIST` },
          ]
      )
    }),
    createItem: builder.mutation<ItemRecord, ItemRecordCreate>({
      query: ({itemContainerId, ...args}) => ({
        url: `itemContainers/${itemContainerId}/items`,
        method: 'POST',
        body: args,
      }),
      transformResponse: (resp: any) => resp.item,
      invalidatesTags: (_result, _error, {itemContainerId}) => ([
        { type: 'Item', id: `${itemContainerId}:LIST` },
      ])
    }),
    updateItem: builder.mutation<ItemRecord, ItemRecordUpdate>({
      query: ({itemContainerId, id, ...args}) => ({
        url: `itemContainers/${itemContainerId}/items/${id}`,
        method: 'PATCH',
        body: args,
      }),
      transformResponse: (resp: any) => resp.item,
      invalidatesTags: (_result, _error, {id}) => ([
        { type: 'Item', id },
      ]),
    }),
    deleteItem: builder.mutation<void, ItemRecordDelete>({
      query: ({itemContainerId, id}) => ({
        url: `itemContainers/${itemContainerId}/items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, {id}) => ([
        { type: 'Item', id },
      ]),
    }),
    getItemByItemContainerIdAndItemId: builder.query<ItemRecord, [string, string]>({
      query: ([itemContainerId, itemId]) => `itemContainers/${itemContainerId}/items/${itemId}`,
      transformResponse: (resp: any) => resp.item,
      providesTags: (_result, _error, [_itemContainerId, itemId]) => ([
        { type: 'Item', id: itemId },
      ]),
    }),
    getItemHistoriesByItemContainerIdAndItemId: builder.query<ItemHistoryRecord[], [string, string]>({
      query: ([itemContainerId, itemId]) => `itemContainers/${itemContainerId}/items/${itemId}/history`,
      transformResponse: (resp: any) => resp.itemHistories,
      providesTags: (result, _error, [_itemContainerId, itemId]) => (
        result ?
          [
            ...result.map(({ id }) => ({ type: 'ItemHistory', id } as const)),
            { type: 'ItemHistory', id: `${itemId}:LIST` },
          ] : [
            { type: 'ItemHistory', id: `${itemId}:LIST` },
          ]
      )
    }),
    createItemHistory: builder.mutation<ItemHistoryRecord, ItemHistoryRecordCreate>({
      query: ({itemContainerId, itemId, ...args}) => ({
        url: `itemContainers/${itemContainerId}/items/${itemId}/history`,
        method: 'POST',
        body: args,
      }),
      transformResponse: (resp: any) => resp.item,
      invalidatesTags: (_result, _error, { itemId }) => ([
        { type: 'ItemHistory', id: `${itemId}:LIST` },
        { type: 'Item', id: itemId },
      ]),
    }),
  }),
})

export const {
  // User Metadata
  useGetUserMetadataQuery,

  // Product Search
  useGetProductSearchQuery,

  // Item Containers
  useGetItemContainersQuery,
  useCreateItemContainerMutation,
  useUpdateItemContainerMutation,
  useGetItemContainerByIdQuery,

  // Items
  useGetItemsByItemContainerIdQuery,
  useCreateItemMutation,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useGetItemByItemContainerIdAndItemIdQuery,

  // Item history
  useGetItemHistoriesByItemContainerIdAndItemIdQuery,
  useCreateItemHistoryMutation,
} = api
